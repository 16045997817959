import React, { useEffect, useState, useRef, useCallback } from 'react'
import Index from '../view/Index'
import _UserRight from '../config/UserRight'
import { Config, IISMethods, LoginInfo, JsCall, setReqParams, resBody } from '../config/IISMethods'
import usePushNotifications from '../swnotification/usePushNotifications'
import { connect } from 'react-redux'
import { setCookiesData, setLocalStorageData, setProps } from '../redux/actions'
import { store } from '../index'


const SignIn = (props) => {

    // get updated value from redux store
    const getCurrentState = () => {
        return store.getState()
    }

    const [state, setState] = useState({
        loginData: IISMethods.getLocalData("loginCredentials") || {
            username: '',
            loginpassword: '',
            rememberme: false,
        },
        forgetData: {
            forgetpassword: false,
            email: '',
        },
        otpData: {
            verifyotp: false,
            otp: '',
            newpassword: '',
            confirmnewpassword: ''
        },
        signUpData: {
            firstname: '',
            lastname: '',
            email: '',
            companyname: '',
            subdomainname: '',
            countryid: 'in',
            countryname: 'India',
            codecontact: '91',
            contact: '',
            password: '',
            confirmpassword: '',
            termsandcondition: 0
        },
        loginValidations: [{
            formfields: [
                {
                    field: 'username',
                    type: 'input-text',
                    required: true,
                    defaultvisibility: true,
                    // regex: `${Config.getRegex()['email']}|${Config.getRegex()['phone']}`
                    regex: Config.getRegex()['email']
                },
                {
                    field: 'loginpassword',
                    type: 'input-text',
                    defaultvisibility: true,
                    required: true,
                    // regex: Config.getRegex()['password']
                }
            ]
        }],
        signUpValidations: [{
            formfields: [
                {
                    field: 'firstname',
                    type: 'input-text',
                    defaultvisibility: true,
                    required: true
                },
                {
                    field: 'lastname',
                    type: 'input-text',
                    defaultvisibility: true,
                    required: true
                },
                {
                    field: 'email',
                    type: 'input-text',
                    required: true,
                    defaultvisibility: true,
                    regex: Config.getRegex()['email']
                },
                {
                    field: 'companyname',
                    'type': 'input-text',
                    defaultvisibility: true,
                    'required': true
                },
                {
                    field: 'subdomainname',
                    type: 'input-text',
                    defaultvisibility: true,
                    required: true
                },
                // {
                //     field : 'countryid',
                //     type : 'dropdown',
                //     required : true
                // },
                // {
                //     field : 'countryname',
                //     type : 'input-text',
                //     required : true
                // },
                // {
                //     field : 'codecontact',
                //     type : 'dropdown',
                //     required : true
                // },
                {
                    field: 'contact',
                    type: 'input-text',
                    required: true,
                    defaultvisibility: true,
                    regex: Config.getRegex()['phone']
                },
                {
                    field: 'password',
                    type: 'input-text',
                    required: true,
                    defaultvisibility: true,
                    regex: Config.getRegex()['password']
                }
            ],

        }],
        forgetPasswordValidations: [{
            formfields: [
                {
                    field: 'email',
                    type: 'input-text',
                    required: true,
                    defaultvisibility: true,
                    regex: Config.getRegex()['email']
                },
            ]
        }],
        resetPasswordValidations: [{
            formfields: [
                {
                    field: 'otp',
                    type: 'input-number',
                    required: true,
                    defaultvisibility: true,
                    minlength: 6,
                    maxlength: 6
                },
                {
                    field: 'newpassword',
                    type: 'input-text',
                    required: true,
                    defaultvisibility: true,
                    regex: Config.getRegex()['password']
                },
                {
                    field: 'confirmnewpassword',
                    type: 'input-text',
                    required: true,
                    defaultvisibility: true,
                    regex: Config.getRegex()['password']
                },
            ]
        }],
        addButtonDisable: false
    })

    // For 2FA
    const [is2FA, setIs2FA] = useState(false)
    const [res2FAData, setRes2FAData] = useState({})
    const [recoveryButtonDisable, setRecoveryButtonDisable] = useState(false)
    const [reqRecoveryData, setReqRecoveryData] = useState({})
    const inputRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
    const [otpState, setOtpState] = useState({
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        otp5: '',
        otp6: '',
    }) // OTP State
    const [otpError, setOtpError] = useState({}) // OTP Error


    // used to identify component mount or unmount
    const isMounted = useRef(true)

    useEffect(() => {
        async function runUseEffect() {
            await getAccessToken()
            if (state.loginData.rememberme) {

            }

        }
        runUseEffect()

        return () => {
            isMounted.current = false
        }
    }, [])

    const getAccessToken = async () => {

        const url = Config.weburl + 'getaccesstoken'

        let reqData = {
        }

        const urlparams = Config.getServermode() == 'prod' ? window.location : new URLSearchParams(window.location.search)

        let headers = {
            key: IISMethods.getCurrentState()['REACT_APP_KEY'],
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            version: Config.version
        }

        if (Config.encryptdata) {
            headers = { reqheader: IISMethods.encryptData(headers) }
        }

        // await IISMethods.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqData, headers, successCallback, errorCallback)
        await IISMethods.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqData, headers, successCallback, errorCallback);

        var resp

        function successCallback(response) {
            if (Config.encryptdata) {
                resp = {}

                resp.status = response.status
                resp.headers = response.headers
                resp.data = response.data
                resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(response.data)))
            }
            else {
                resp = response
            }
        }

        function errorCallback(error) {
            if (Config.encryptdata) {
                resp = JSON.parse(JSON.parse(IISMethods.decryptData(error)))
                if (resp.response.data.updateversion) {
                    IISMethods.HardRefresh()
                }
            }
            else {
                resp = error
                if (resp.response.data.updateversion) {
                    IISMethods.HardRefresh()
                }
            }
        }

        if (resp.status === 200) {
            LoginInfo.setToken(resp.headers.token)
            LoginInfo.setUnqkey(resp.data.data.unqkey)
            LoginInfo.setUid(resp.data.data.uid)
        }
        else {
            //getAccessToken()
        }
    }

    const handleSignIn = (e) => {
        let states = state.loginData
        if (e.target.name !== "rememberme") {
            states[e.target.name] = e.target.value
            // JsCall.handleHasVal('form-'+e.target.name, e.target.value)
        }
        else {
            states[e.target.name] = e.target.checked
        }
        setState({ ...state, loginData: states })

        JsCall.validateForm(state.loginData, state.loginValidations, e.target.name)
    }

    // click on sign in
    const signIn = async (e, subdomainname) => {
        if (!subdomainname)
            e.preventDefault()

        // const urlparams = new URLSearchParams(window.location.search)
        const urlparams = Config.getServermode() == 'prod' ? window.location : new URLSearchParams(window.location.search)
        let isValid2FAOtp = true
        if (is2FA) {
            isValid2FAOtp = handle2FAValidation()
        }
        if (!JsCall.validateForm(state.loginData, state.loginValidations)) {
            const url = Config.weburl + 'login'

            if (state.loginData.rememberme) {
                IISMethods.setLocalData("loginCredentials", state.loginData)
            }
            else {
                IISMethods.clearLocalData("loginCredentials")
            }

            let reqData = {}
            let useraction = ''

            if (is2FA) {
                let otp2FA = Object.values(otpState).join('')
                reqData = {
                    email: state.loginData.username,
                    password: state.loginData.loginpassword,
                    twoFACode: otp2FA,
                    requestid: res2FAData.requestid
                }
                useraction = '2FAlogin'
            }
            else {
                reqData = {
                    email: state.loginData.username,
                    password: state.loginData.loginpassword
                }

                useraction = 'login'
            }

            let headers = {
                token: LoginInfo.getToken(),
                issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
                unqkey: LoginInfo.getUnqkey(),
                uid: LoginInfo.getUid(),
                platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
                useraction: useraction,
                pagename: 'login',
                version: Config.version
            }

            // if (!headers.subdomainname) {
            //     window.location.href = `/${IISMethods.getpagename()}?q=IIS`
            //     return
            // }

            if (Config.encryptdata) {
                headers = { reqheader: IISMethods.encryptData(headers) }
                reqData = { reqbody: IISMethods.encryptData(reqData) }
            }


            var resp = {}

            // await IISMethods.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqData, headers, successCallback, errorCallback)
            await IISMethods.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqData, headers, successCallback, errorCallback)


            function successCallback(response) {
                if (Config.encryptdata) {
                    resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(response.data)))
                    resp.headers = response.headers
                    resp.status = response.status
                }
                else {
                    resp = response
                }
            }

            function errorCallback(error) {
                if (Config.encryptdata) {
                    resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.data)))

                    if (resp.data.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    resp.headers = error.response.headers
                    resp.status = error.response.status
                }
                else {
                    resp = error.response
                    if (resp.data.updateversion) {
                        IISMethods.HardRefresh()
                    }
                }
            }

            if (resp.status === 200) {

                if (resp?.data?.data?.is2FAenable && resp?.data?.data?.requestid) {

                    setRes2FAData(resp.data.data)
                    setIs2FA(true)
                    setState({ ...state, addButtonDisable: false })

                    LoginInfo.setToken(resp.headers.token)
                    LoginInfo.setUnqkey(resp.headers.unqkey)
                    const data = resp.data.data
                    var userrole = data.userrole
                    LoginInfo.setUid(data._id)
                    LoginInfo.setFirstname(data.firstname)
                    LoginInfo.setLastname(data.lastname)
                    LoginInfo.setUserroleID(userrole[0].userroleid)

                    setReqRecoveryData(LoginInfo)
                }
                else {

                    // LoginInfo.setSubdomainName(resp.headers.subdomainname)
                    LoginInfo.setToken(resp.headers.token)
                    LoginInfo.setUnqkey(resp.headers.unqkey)
                    const data = resp.data.data[0]
                    LoginInfo.setFirstname(data.firstname)
                    LoginInfo.setLastname(data.lastname)
                    LoginInfo.setContact(data.contact)
                    LoginInfo.setPersonemail(data.personemail)
                    LoginInfo.setUid(data._id)
                    LoginInfo.setTheme(data.theme)
                    LoginInfo.setDateFormate(7)
                    LoginInfo.setTimeFormate(1)
                    LoginInfo.setReferralcode(data.referralcode)
                    LoginInfo.setUserTypeId(data.usertypeid)

                    var userrole = data.userrole
                    LoginInfo.setUserroles(userrole)
                    LoginInfo.setUserroleID(userrole && userrole[0].userroleid)

                    try {
                        LoginInfo.setStatus(data.status)
                        LoginInfo.setIsActive(data.isactive)
                        LoginInfo.setBranches(data.branch)
                        LoginInfo.setBranchID(data.branch[0]?.branchid)
                    }
                    catch {

                    }
                    LoginInfo.setCompanyID(data.companyid)
                    LoginInfo.setCompanyName(data.companyname)
                    LoginInfo.setCompanyCountryID(data.companycountryid)
                    LoginInfo.setCompanyStateId(data.companystateid)
                    LoginInfo.setCompanyCityId(data.companycityid)

                    LoginInfo.setProfilePic(data.profilepic)


                    // temp comment 
                    // var userrights = new Array()

                    // try {
                    //     resp.data.userrights.map((obj) => {
                    //         var UserRight = new _UserRight()

                    //         UserRight.setID(obj._id)
                    //         UserRight.setMenuname(obj.menuname)
                    //         // UserRight.setFormname(obj.formname)
                    //         UserRight.setAlias(obj.alias)
                    //         // UserRight.setContainright(obj.containright)
                    //         UserRight.setAllviewright(obj.allviewright)
                    //         UserRight.setSelfviewright(obj.selfviewright)
                    //         UserRight.setAlladdright(obj.alladdright)
                    //         UserRight.setSelfaddright(obj.selfaddright)
                    //         UserRight.setAlleditright(obj.alleditright)
                    //         UserRight.setSelfeditright(obj.selfeditright)
                    //         UserRight.setAlldelright(obj.alldelright)
                    //         UserRight.setSelfdelright(obj.selfdelright)
                    //         UserRight.setAllprintright(obj.allprintright)
                    //         UserRight.setSelfprintright(obj.selfprintright)
                    //         UserRight.setRequestright(obj.requestright)
                    //         UserRight.setChangepriceright(obj.changepriceright)

                    //         userrights.push(UserRight)
                    //     })
                    // }
                    // catch {
                    //     getAccessToken()
                    // }

                    // IISMethods.setLocalData("userRights", userrights)
                    // IISMethods.setLocalData("menuData", resp.data.menudata)

                    IISMethods.setLocalData("iconExtension", resp.data.iconextension)
                    IISMethods.setCookiesData("loginInfo", LoginInfo)
                    // var pagename = Config.getServermode() == 'prod' ? new URLSearchParams(urlparams.search).get('targeturl') : urlparams.get('targeturl')
                    var pagename = Config.getServermode() == 'prod' ? `${window.location.pathname.replace('/', '')}${window.location.search?.length ? window.location.search : ``}` : `${window.location.pathname.replace('/', '')}${window.location.search?.length ? window.location.search : ``}`

                    // temp comment
                    if (!resp?.data?.data[0]?.status) {

                        if (window.location.pathname == '/login' || window.location.pathname == '/') {
                            pagename = 'home'
                        }

                        if (!pagename) {
                            pagename = 'home'
                        }

                        window.location.href = `/${pagename}`
                    } else {

                    }

                    setState({ ...state, addButtonDisable: false })
                }

            }
            else {
                var msg = resp.data.message
                if (resp.data.status === 401 && msg === 'Unauthorized')
                    msg = Config.getErrmsg()['invalidusername']
                IISMethods.notify(msg, 2)
                setState({ ...state, addButtonDisable: false })
            }

            // if (resp.status === 400) {
            //     setTimeout(() => {
            //         window.location.href = '/'
            //     }, 1000)
            // }
        }
        else {
            setState({ ...state, addButtonDisable: false })
        }
    }

    const handleSignUp = (type, key, value) => {
        if (type === "checkbox") {
            state.signUpData[key] = (value ? 1 : 0)
        }
        else if (type === 'text') {
            state.signUpData[key] = value
            // JsCall.handleHasVal('form-'+key, value)
        }
        else {
            if (!value || /^\d+$/.test(value)) {
                state.signUpData[key] = value
                // JsCall.handleHasVal('form-'+key, value)
            }
            else if (typeof (value) === "object") {
                state.signUpData.codecontact = value.dialCode
                state.signUpData.countryid = value.iso2
                state.signUpData.countryname = value.name.split(" ")[0]
            }
        }
        setState({ ...state, signUpData: state?.signUpData })
    }

    const signUp = async (e) => {
        e.preventDefault()

        var formData = IISMethods.getCopy(state?.signUpData)

        formData.contact = formData.codecontact + formData.contact

        // delete formData.codecontact

        if (!JsCall.validateForm(formData, state?.signUpValidations) && state?.signUpData?.password === state?.signUpData?.confirmpassword) {
            const url = Config.weburl + 'signup'

            delete formData.confirmpassword

            let reqData = formData

            let headers = {
                token: LoginInfo.getToken(),
                issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
                unqkey: LoginInfo.getUnqkey(),
                uid: LoginInfo.getUid(),
                platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
                useraction: 'signup',
                pagename: IISMethods.getpagename()
            }

            // temp comment
            // await IISMethods.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqData, headers, successCallback, errorCallback)

            var resp

            function successCallback(response) {
                resp = response
            }

            function errorCallback(error) {
                resp = error.response
            }


            if (resp.status === 200) {
                state.loginData.username = state.signUpData?.email
                state.loginData.loginpassword = state.signUpData?.password
                setState({ ...state, loginData: state?.loginData })
                signIn(e, reqData.subdomainname)
            }
            else {
                IISMethods.notify(resp.data.message, 2)
                setState({ ...state, addButtonDisable: false })
            }
        }
        else {
            JsCall.handleError('form-confirmpassword', !state?.signUpData.confirmpassword || state?.signUpData.password !== state.signUpData.confirmpassword)
            setState({ ...state, addButtonDisable: false })
        }
    }

    const handleForgetPassword = (e) => {
        let states = state.forgetData
        if (e.target.name !== "rememberme") {
            states[e.target.name] = e.target.value.trim()
        }
        else {
            states[e.target.name] = e.target.checked
        }
        setState({ ...state, forgetData: { ...states } })
        JsCall.validateForm(state.forgetData, state.forgetPasswordValidations, e.target.name)

    }

    const forgetPassword = async (e, subdomainname) => {
        e.preventDefault()
        const urlparams = Config.getServermode() == 'prod' ? window.location : new URLSearchParams(window.location.search)
        if (!JsCall.validateForm(state.forgetData, state?.forgetPasswordValidations)) {
            const url = Config.weburl + 'forgotpasswordrequest'

            let reqData = state.forgetData

            let headers = {
                token: LoginInfo.getToken(),
                issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
                unqkey: LoginInfo.getUnqkey(),
                uid: LoginInfo.getUid(),
                platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
                useraction: 'forgetpassword',
                pagename: IISMethods.getpagename(),
                // subdomainname: subdomainname || Config.getServermode() == 'prod' ? urlparams.hostname?.split('.')[0] : urlparams.get('q'),
                version: Config.version

            }

            // hemang jani 30/01/23
            if (Config.encryptdata) {
                headers = { reqheader: IISMethods.encryptData(headers) }
                reqData = { reqbody: IISMethods.encryptData(reqData) }
            }

            let resp = {}
            // await IISMethods.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqData, headers, successCallback, errorCallback)
            await IISMethods.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqData, headers, successCallback, errorCallback)



            // hemang jani 30/01/23
            function successCallback(response) {
                if (Config.encryptdata) {
                    resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(response.data)))
                    resp.headers = response.headers
                    resp.status = response.status
                }
                else {
                    resp = response
                }
            }

            // hemang jani 30/01/23
            function errorCallback(error) {
                if (Config.encryptdata) {
                    resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.data)))
                    if (resp.data.updateversion) {
                        IISMethods.HardRefresh()
                    }
                    resp.headers = error.response.headers
                    resp.status = error.response.status
                }
                else {
                    resp = error.response
                    if (resp.data.updateversion) {
                        IISMethods.HardRefresh()
                    }
                }
            }
            if (resp.status === 200) {
                setState({ ...state, forgetData: { ...state.forgetData, forgetpassword: !state.forgetData.forgetpassword }, otpData: { ...state.otpData, verifyotp: !state.otpData.verifyotp }, addButtonDisable: false })
                IISMethods.localnotify(resp.data.message, 1)
            }
            else {
                IISMethods.notify(resp.data.message, 2)
                setState({ ...state, addButtonDisable: false })
            }
        }
        else {
            setState({ ...state, addButtonDisable: false })
            IISMethods.notify(Config.errmsg.invalidemail, 2)
        }
    }

    const handleOtp = (e) => {
        let states = state.otpData
        if (e.target.name !== "rememberme") {
            states[e.target.name] = (e.target.value).trim()
        }
        else {
            states[e.target.name] = e.target.checked
        }
        setState({ ...state, otpData: states })
    }

    const changePassword = async (e, subdomainname) => {
        e.preventDefault()
        const urlparams = Config.getServermode() == 'prod' ? window.location : new URLSearchParams(window.location.search)

        var formData = {
            otp: state.otpData.otp,
            newpassword: state.otpData.newpassword,
            confirmnewpassword: state.otpData.confirmnewpassword,
        }

        if (!JsCall.validateForm(formData, state?.resetPasswordValidations)) {
            if (state.otpData?.confirmnewpassword !== state.otpData?.newpassword) {
                IISMethods.notify(Config.errmsg.passwordnotmatch, 2)
                setState({ ...state, addButtonDisable: false })
            } else {

                var formData = { ...formData, 'email': state.forgetData.email }
                const url = Config.weburl + 'resetpassword'

                let reqData = formData

                let headers = {
                    token: LoginInfo.getToken(),
                    issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
                    unqkey: LoginInfo.getUnqkey(),
                    uid: LoginInfo.getUid(),
                    platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
                    useraction: 'resetpassword',
                    pagename: IISMethods.getpagename(),
                    // subdomainname: subdomainname || Config.getServermode() == 'prod' ? urlparams.hostname?.split('.')[0] : urlparams.get('q')
                    version: Config.version
                }

                // hemang jani 30/01/23
                if (Config.encryptdata) {
                    headers = { reqheader: IISMethods.encryptData(headers) }
                    reqData = { reqbody: IISMethods.encryptData(reqData) }
                }

                var resp = {}
                // await IISMethods.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqData, headers, successCallback, errorCallback)
                await IISMethods.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqData, headers, successCallback, errorCallback)



                function successCallback(response) {
                    if (Config.encryptdata) {
                        resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(response.data)))
                        resp.headers = response.headers
                        resp.status = response.status
                    }
                    else {
                        resp = response
                    }
                }

                function errorCallback(error) {
                    if (Config.encryptdata) {
                        resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.data)))
                        if (resp.data.updateversion) {
                            IISMethods.HardRefresh()
                        }
                        resp.headers = error.response.headers
                        resp.status = error.response.status
                    }
                    else {
                        resp = error.response
                        if (resp.data.updateversion) {
                            IISMethods.HardRefresh()
                        }
                    }
                }


                if (resp.status === 200) {
                    setState({ ...state, forgetData: { ...state.forgetData, forgetpassword: false, email: '' }, otpData: { ...state.otpData, verifyotp: false, newpassword: '', confirmnewpassword: '', otp: '' }, addButtonDisable: false })
                    IISMethods.notify(resp.data.message, 2)
                }
                else {
                    IISMethods.notify(resp.data.message, 2)
                    setState({ ...state, addButtonDisable: false })
                }
            }
        }
        else {
            setState({ ...state, addButtonDisable: false })
        }

    }

    const handleGrid = useCallback(
        (id, type, key, value, editeDataIndex) => {

            if (type === 'switch' || type === 'checkbox') {
                var object = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
                object[key] = (value ? 1 : 0)
                // updateData(object, editeDataIndex)
            }
            else if (type === 'dropdown') {
                var object = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
                const fieldobj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'field', key)
                const masterdataobject = IISMethods.getobjectfromarray(getCurrentState().masterData[fieldobj.masterdata], 'value', value)
                object[key] = masterdataobject.label
                object[key + 'id'] = masterdataobject.value
                // updateData(object, editeDataIndex)
            }
            else if (type === 'modal') {
                if (value) {
                    getCurrentState().modal[key] = id
                }
                else {
                    delete getCurrentState().modal[key]
                }
                props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
            }
            else {

            }

        }
        ,
        [state]
    )


    //********** 2FA Starts
    const handle2FAValidation = (key, value) => {
        let otperr = IISMethods.getCopy(otpError)
        let keyVal = Object.keys(otpState).filter((key) => otpState[key] === '')
        if (key) { if (value) delete otperr[key] }
        else for (let key of keyVal) { otperr[key] = 1 }
        setOtpError(IISMethods.getCopy(otperr))
        return keyVal.length === 0
    }

    const handleOTPChange = (type, key, value, index) => {
        let otpdata = IISMethods.getCopy(otpState)
        if (type === 'number-input') {
            otpdata[key] = value ? value.at(-1) : ''
            handle2FAValidation(key, value)
            if (value && index !== (inputRef.length - 1)) inputRef[index + 1].current.focus()

        }
        setOtpState(IISMethods.getCopy(otpdata))
    }

    const handleVerifyOTP = async (otp) => {

        let url = Config.weburl + '2FA/recoverycode/verify'
        let useraction = 'addright'

        let reqBody = {
            email: state.loginData.username,
            password: state.loginData.loginpassword,
            recoverycode: otp,
            requestid: res2FAData.requestid
        }

        let resBody = {}
        let resHeaders = {}

        let reqheader = {
            token: reqRecoveryData.token,
            issuer: IISMethods.getCurrentState()['REACT_APP_ISSUER'],
            unqkey: reqRecoveryData.unqkey,
            uid: reqRecoveryData.uid,
            platform: IISMethods.getCurrentState()['REACT_APP_PLATFORM'],
            useraction: useraction,
            pagename: IISMethods.getpagename(),
            subdomainname: reqRecoveryData.subdomainname,
            username: reqRecoveryData.firstname + ' ' + reqRecoveryData.lastname,
            companyid: reqRecoveryData.companyid,
            branchid: reqRecoveryData.branchid,
            userroleid: reqRecoveryData.userroleid,
            version: Config.version,
            moduletype: 'web'
            // for public ip
            // publicip : loginInfo.clientpublicip
        }


        // hemang jani 30/01/23
        // encrypt req header
        if (Config.encryptdata) {
            reqheader = { reqheader: IISMethods.encryptData(reqheader) }
        }
        else {
            reqheader = { ...reqheader }
        }

        if (Config.encryptdata) {
            if (!reqBody.reqbody) {
                reqBody = { reqbody: IISMethods.encryptData(reqBody) }
            }
        }

        await IISMethods.axiosrequest(IISMethods.getCurrentState()['REACT_APP_METHOD_POST'], url, reqBody, reqheader, addDataSuccessCallback, addDataErrorCallback)

        function addDataSuccessCallback(res) {
            // console.log(res,'resresresres')

            if (Config.encryptdata) {
                try {

                    resBody = JSON.parse(JSON.parse(this.decryptData(res.data)))

                }
                catch (e) {
                    // 
                    resBody = res
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (res.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers)))
                }
                else {
                    resHeaders = res.headers
                }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function addDataErrorCallback(error) {

            console.log(error, 'resresresres')
            if (Config.encryptdata) {
                try {
                    resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data)))
                }
                catch (e) {
                    resBody = error.response.data
                }

                if (resBody.updateversion) {
                    IISMethods.HardRefresh()
                }

                // decrypt res header
                // hemang jani 30/01/23
                if (error.response.headers.reqheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers)))
                }
                else {
                    resHeaders = error.response.headers
                }
            }
            else {
                resBody = error.response?.data
                if (resBody.updateversion) {
                    IISMethods.HardRefresh()
                }
                // hemang jani 30/01/23
                resHeaders = error.response.headers

            }
        }

        if (resBody.status === 200) {
            IISMethods.localnotify(resBody.message, 1)
            await handleGrid(false, 'modal', 'twofactorauth', 0)
            await handleGrid(false, 'modal', 'recoveycode', 0)
            setIs2FA(false)
            setRes2FAData({})
            setReqRecoveryData({})
            setRecoveryButtonDisable(false)
        }
        else {
            setRecoveryButtonDisable(false)
            IISMethods.localnotify(resBody.message, resBody.status)
        }
    }
    //********** 2FA ends

    return (
        <Index
            handleSignIn={handleSignIn}
            signIn={signIn}
            handleSignUp={handleSignUp}
            signUp={signUp}
            tnc={state?.signUpData?.termsandcondition}
            phoneno={state?.signUpData?.contact}
            loginData={state?.loginData}
            setState={setState}
            state={state}
            handleForgetPassword={handleForgetPassword}
            forgetPassword={forgetPassword}
            handleOtp={handleOtp}
            changePassword={changePassword}
            is2FA={is2FA}
            setIs2FA={setIs2FA}
            inputRef={inputRef}
            otpState={otpState}
            otpError={otpError}
            handleOTPChange={handleOTPChange}
            recoveryButtonDisable={recoveryButtonDisable}
            setRecoveryButtonDisable={setRecoveryButtonDisable}
            handleVerifyOTP={handleVerifyOTP}
            handleGrid={handleGrid}
        />
    )
}
const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setLocalStorageData: (payload) => dispatch(setLocalStorageData(payload)),
    setCookiesData: (payload) => dispatch(setCookiesData(payload)),
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
