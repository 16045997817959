import React from 'react'

const ActionIcons = (props) => {
    let icon

    if (props.type === 'edit') {
        icon = <i className={`fi fi-rr-pen-square ${props.className ? props.className : ""}`} />
    } else if (props.type === 'delete') {
        icon = <i className={`fa-regular fa-trash-can ${props.className}`} />
    } else if (props.type === 'info') {
        icon = <i className={`fi fi-rr-info ${props.className}`} />
    } else if (props.type === 'visibility') {
        icon = <i className={`fi fi-rr-eye ${props.className}`} />
    } else if (props.type === 'archive') {
        icon = <i className={`fi fi-rr-folder-download ${props.className}`} />
    } else if (props.type === 'unarchive') {
        icon = <i className={`fi fi-rr-folder-upload ${props.className}`} />
    } else if (props.type === 'decorateroom') {
        icon = <i className={`fa-solid fa-calendar-xmark ${props.className}`} />
    } else if (props.type === 'key') {
        icon = <i className={`fi fi-rr-key ${props.className}`} />
    } else if (props.type === 'copy') {
        icon = <i className={`fi fi-rr-copy-alt ${props.className}`} />
    } else if (props.type === 'cancle') {
        icon = <i className={`fa-solid fa-xmark ${props.className}`} />
    } else if (props.type === 'add') {
        icon = <i className={`fi fi-rr-plus ${props.className}`} />
    } else if (props.type === 'dragindicator') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>drag_indicator</i>
    } else if (props.type === 'gridview') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}}>grid_view</i>
    } else if (props.type === 'listview') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>view_list</i>
    } else if (props.type === 'filter') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} style={props.style ? props.style : {}}>filter_alt</i>
    } else if (props.type === 'editdetails') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} 
                    onClick={props.onClick ? props.onClick : () => {}} 
                    disabled={props.disabled ? props.disabled : ""} 
                    style={props.style ? props.style : {}}>edit
                </i>
    } else if (props.type === 'north') {
        icon = <i className={`material-icons-outlined ${props.className ? props.className : ""}`} style= {props.style ? props.style : {}} >north</i>
    } else if (props.type === 'leftside') {
        icon = <i className={`fa-solid fa-angles-left ${props.className ? props.className : ""}`} style= {props.style ? props.style : {}}/>
    } else if (props.type === 'rightside') {
        icon = <i className={`fa-solid fa-angles-right ${props.className ? props.className : ""}`} style= {props.style ? props.style : {}}/>
    } else if (props.type === 'appregistration') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>app_registration</i>
    } else if (props.type === 'more_vert') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} style={props.style ? props.style : {}}>more_vert</i>
    } else if (props.type === 'more_horiz') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} style={props.style ? props.style : {}}>more_horiz</i>
    } else if (props.type === 'version') {
        icon = <i className={`fa-solid fa-sitemap ${props.className ? props.className : ""}`} />
    } else if (props.type === 'visibilityfill') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}} style={props.style ? props.style : {}}>visibility</i>
    } else if (props.type === 'addfield') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} style={props.style ? props.style : {}} onClick={props.onClick ? props.onClick : () => {}} id= {props.id ? props.id : ""}>add</i>
    } else if (props.type === 'infofill') {
        icon = <i className={`fi fi-sr-info ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}} />
    } else if (props.type === 'setorder') {
        icon = <i className={`fa-solid fa-arrow-up-a-z ${props.className ? props.className : ""}`} />
    } else if (props.type === 'close') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}}>close</i>
    } else if (props.type === 'link') {
        icon = <i className={`fa-solid fa-link ${props.className ? props.className : ""}`} />
    } else if (props.type === 'language') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>language</i>
    } else if (props.type === 'envelope') {
        icon = <i className={`fa-regular fa-envelope ${props.className ? props.className : ""}`} />
    } else if (props.type === 'verified') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>verified</i>
    } else if (props.type === 'editdata') {
        icon = <i className={`fa-solid fa-pen ${props.className ? props.className : ""}`} />
    } else if (props.type === 'description') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>description</i>
    } else if (props.type === 'search') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>search</i>
    } else if (props.type === 'deleteicon') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>delete</i>
    } else if (props.type === 'copycode') {
        icon = <i className={`fa-regular fa-copy ${props.className ? props.className : ""}`} />
    } else if (props.type === 'user') {
        icon = <i className={`fa-regular fa-user ${props.className ? props.className : ""}`} />
    } else if (props.type === 'phone') {
        icon = <i className={`fa-solid fa-phone ${props.className ? props.className : ""}`} />
    } else if (props.type === 'arrowleft') {
        icon = <i className={`fa-solid fa-arrow-left ${props.className ? props.className : ""}`} />
    } else if (props.type === 'arrowright') {
        icon = <i className={`fa-solid fa-arrow-right ${props.className ? props.className : ""}`} />
    } else if (props.type === 'check') {
        icon = <i className={`fa-solid fa-check ${props.className ? props.className : ""}`} />
    } else if (props.type === 'supervisoraccount') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>supervisor_account</i>
    } else if (props.type === 'redeem') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>redeem</i>
    } else if (props.type === 'shoppingcart') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>shopping_cart</i>
    } else if (props.type === 'creditcard') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>credit_card</i>
    } else if (props.type === 'person') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>person</i>
    } else if (props.type === 'assignmentind') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>assignment_ind</i>
    } else if (props.type === 'deleteoutlined') {
        icon = <i className={`material-icons-outlined ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}} >delete</i>
    } else if (props.type === 'visibilityoutlined') {
        icon = <i className={`material-icons-outlined ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}} style={props.style ? props.style : {}}>visibility</i>
    } else if (props.type === 'downloadfile') {
        icon = <i className={`fa-solid fa-download ${props.className ? props.className : ""}`} />
    } else if (props.type === 'pending') {
        icon = <i className={`fa-solid fa-clock-rotate-left ${props.className ? props.className : ""}`} />
    } else if (props.type === 'approve') {
        icon = <i className={`fa-regular fa-circle-check ${props.className ? props.className : ""}`} />
    } else if (props.type === 'reject') {
        icon = <i className={`fa-regular fa-circle-xmark ${props.className ? props.className : ""}`} />
    } else if (props.type === 'selecttag') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>data_object</i>
    } else if (props.type === 'listview') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>view_list</i>
    } else if (props.type === 'gridview') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>grid_view</i>
    } else if (props.type === 'arrowdown') {
        icon = <i className={`fa-solid fa-chevron-down ${props.className ? props.className : ""}`} />
    } else if (props.type === 'solidcheck') {
        icon = <i className={`fa-solid fa-circle-check ${props.className ? props.className : ""}`} />
    } else if (props.type === 'addcircleoutline') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>add_circle_outline</i>
    } else if (props.type === 'removecircleoutline') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>remove_circle_outline</i>
    } else if (props.type === 'camera') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>photo_camera</i>
    } else if (props.type === 'notification') {
        icon = <i className={`fa-solid fa-bell ${props.className ? props.className : ""}`} />
    } else if (props.type === 'daterange') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} style={props.style ? props.style : {}}>date_range</i>
    } else if (props.type === 'keyboardarrowdown') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} style={props.style ? props.style : {}}>keyboard_arrow_down</i>
    } else if (props.type === 'infoicon') {
        icon = <i className={`fa-solid fa-circle-info ${props.className ? props.className : ""}`} />
    } else if (props.type === 'sync') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>sync</i>
    } else if (props.type === 'searchicon') {
        icon = <i className={`bi bi-search ${props.className ? props.className : ""}`} />
    } else if (props.type === 'list') {
        icon = <i className={`bi bi-list ${props.className ? props.className : ""}`} />
    } else if (props.type === 'notificationicon') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`} id= {props.id ? props.id : ""}>notifications</i>
    } else if (props.type === 'widgets') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>widgets</i>
    } else if (props.type === 'location') {
        icon = <i className={`bi bi-geo-alt ${props.className ? props.className : ""}`} />
    } else if (props.type === 'box') {
        icon = <i className={`bi bi-box ${props.className ? props.className : ""}`} />
    } else if (props.type === 'readmore') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>read_more</i>
    } else if (props.type === 'email') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>email</i>
    } else if (props.type === 'lock') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>lock</i>
    } else if (props.type === 'business') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>business</i>
    } else if (props.type === 'modaltextIcon') {
        icon = <i className={`material-icons-round text-primary ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}} style={props.style ? props.style : {}}>visibility</i>
    } else if (props.type === 'modalOpenIcon') {
        icon = <i className={`material-icons-round text-primary ${props.className ? props.className : ""}`} onClick={props.onClick ? props.onClick : () => {}} style={props.style ? props.style : {}}>visibility</i>
    } else if (props.type === 'favorite') {
        icon = <i className={`material-icons-round ${props.className ? props.className : ""}`}>favorite</i>
    } else if (props.type === 'ellipsis') {
        icon = <i className={`fa-solid fa-ellipsis-vertical ${props.className ? props.className : ""}`} />
    } else if (props.type === 'eyeicon') {
        icon = <i className={`fa-regular fa-eye ${props.className ? props.className : ""}`} />
    }
    else {
        icon = null
    }

    return icon
}

export default ActionIcons

