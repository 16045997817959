import React, { Component } from "react"
import { Tooltip } from 'rsuite'
import { Whisper } from 'rsuite'
import { Config } from "../../config/IISMethods"
import ActionIcons from "./ActionIcons"

const TooltipRsuite = (props) =>{
  return (
    <>
        <Whisper 
            placement={props.placement} 
            controlId={props.controlId}
            trigger={props.trigger}
            speaker={
                <Tooltip >
                    <>
                    <ul className='pl-12 password-tooltip mt-5p'>
                        <li>Must Be at least 8 characters</li>
                        <li>Have at least one number</li>
                        <li>Have at least one symbol !@#$%^&*_</li>
                        <li>Have at least one upper case letter</li>
                        <li>Have at least one lower case letter</li>
                    </ul>
                    </>
                </Tooltip>
            }
        >
            <span><ActionIcons type='infofill' className='text-primary text-12 ml-1' /></span>
        </Whisper>
    </>
  )
}

export default TooltipRsuite

TooltipRsuite.defaultProps = {
    placement : 'top',
    trigger : 'hover',
    controlId : "control-id-hover",
}